import React from "react";
import { VideoBlock as ProjectVideoBlock } from "../Project/";

export const VideoBlock = ({
  video_url,
  placeholder_image,
  className = "video-block__page ",
}) => {
  return (
    <ProjectVideoBlock
      video_url={video_url}
      placeholder_image={placeholder_image}
      className={className}
    />
  );
};

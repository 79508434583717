import React, { Component } from "react";
import { decodeEntities } from "../../utils/helpers";
import "./Accordion.scss";

const isClient = typeof window !== "undefined";
const Item = (props) => {
  const { item, openNavKey, toggleSubNav, keyId } = props;
  const { title, content } = item;
  const isopen = openNavKey === keyId;
  return (
    <div className="accordion-item" key={keyId}>
      <div
        onClick={(event) => toggleSubNav(event, keyId)}
        className={`trigger ${isopen ? "open" : "closed"}`}
      >
        <span className="accordion-item__title">{decodeEntities(title)}</span>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className={`content ${isopen ? "open" : "closed"}`}
      />
    </div>
  );
};
export default class Accordion extends Component {
  state = { openNavKey: 0 };

  componentDidMount() {
    if (isClient) {
      document.addEventListener("keydown", this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener("keydown", this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  };

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey)
      return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  };

  render() {
    const { concertina, active, tabStructure } = this.props;
    const { openNavKey } = this.state;
    return (
      <div className={`accordion-wrap${tabStructure ? " tabs" : ""}`}>
        {tabStructure && (
          <div className="tab-nav">
            {concertina.map((col, index) => (
              <div
                key={index}
                onClick={(event) => this.toggleSubNav(event, index)}
                className={`trigger ${
                  openNavKey === index ? "open" : "closed"
                }`}
              >
                <span className="accordion-wrap__title">
                  {decodeEntities(col.title)}
                </span>
              </div>
            ))}
          </div>
        )}
        <div className={`accordion${active ? " active" : ""}`}>
          {concertina &&
            concertina.map((col, index) => (
              <Item
                key={index}
                keyId={index}
                item={col}
                toggleSubNav={this.toggleSubNav}
                openNavKey={openNavKey}
              />
            ))}
        </div>
      </div>
    );
  }
}

import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import "./StoryBlock.scss";

/**
 * Story Block Component
 * @param {string} title
 * @param {string} content
 * @param {string} accentTitle
 * @param {Object} image
 */
export const StoryBlock = ({ title, content, accentTitle, image }) => {
  return (
    <section className="story-block">
      <div className="wrap">
        <div className="inner">
          <div className="background">
            <ImagePass src={image} />
          </div>
          <div className="over">
            {accentTitle && (
              <p
                className="accent"
                dangerouslySetInnerHTML={{ __html: accentTitle }}
              />
            )}
            {title && (
              <span
                className="h2 title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {content && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

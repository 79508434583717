import React from "react";
import "./LogoFeed.scss";
import { Image } from "../../Image";
import Carousel from "../../Carousel";

const ThisSlider = (props) => {
  const { gallery, index } = props;
  // Gallery must be empty
  if (!gallery?.length > 0) return null;
  const settings = {
    container: `logo-carousel-inner-${index}`,
    loop: false,
    nav: false,
    controls: false,
    mouseDrag: true,
    gutter: 30,
    items: 1,
    responsive: {
      1600: {
        items: 6,
      },
      1024: {
        items: 5,
      },
      640: {
        items: gallery?.length === 1 ? 1 : 4,
      },
      440: {
        items: gallery?.length > 0 ? 2 : 1,
      },
      380: {
        items: 1,
      },
    },
    speed: 600,
    autoplay: true,
  };
  return (
    <div className="logo-carousel">
      <Carousel settings={settings}>
        {gallery &&
          gallery.map(
            (slide, slide_count) =>
              slide.source_url && (
                <div key={slide_count} className="slide">
                  <div className="aspect">
                    <div className="logo">
                      {slide.source_url && (
                        <Image src={slide} position="relative" lazy={false} />
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
      </Carousel>
    </div>
  );
};

const ThisTiles = ({ gallery }) => {
  return (
    <div className="logo-tiles">
      {gallery &&
        gallery.map(
          (slide, slide_count) =>
            slide.source_url && (
              <div key={slide_count} className="tile">
                <div className="aspect">
                  <div className="logo">
                    {slide.source_url && (
                      <Image src={slide} position="relative" lazy={false} />
                    )}
                  </div>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export const LogoFeed = (props) => {
  const { LogoGallery: gallery, title, index, feedStyle } = props;
  return (
    <section className={`logo-feed theme-${feedStyle}`}>
      <div className="wrap">
        <div className="title">
          <h3>{title}</h3>
        </div>
        <div className="inner">
          {feedStyle === "dark" ? (
            <ThisTiles gallery={gallery} />
          ) : (
            <ThisSlider gallery={gallery} index={index} />
          )}
        </div>
      </div>
    </section>
  );
};

import React, { useCallback } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./GoogleMapBlock.scss";
import pinIcon from "./pinIcon.svg";
const settings = require("./mapSettings.json");

export const GoogleMapBlock = ({ showMap }) => {
  // if (!showMap) return null;
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: -20.381332914823904,
    lng: 118.5950671741148,
  };
  const [map, setMap] = React.useState(null);
  const [zoom, setZoom] = React.useState(18);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <section className="section-location-block">
      <div className="google-image-wrapper">
        <LoadScript
          googleMapsApiKey={
            process.env.GATSBY_GOOGLE_MAPS_API_KEY
              ? process.env.GATSBY_GOOGLE_MAPS_API_KEY
              : "AIzaSyAW_BUoPVQTk_x_ZhugvE58KQ7IWxFWq5U" // BB developer key
          }
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onUnmount={onUnmount}
            options={{ styles: settings }}
          >
            <Marker position={center} icon={pinIcon} />
          </GoogleMap>
        </LoadScript>
      </div>
    </section>
  );
};

import React from "react";
import loadable from "@loadable/component";
import RelatedPosts from "../../Related/RelatedPosts";
import RelatedProjects from "../../Related/RelatedProjects";
export const PostFeed = (props) => {
  const { index } = props
  if (props.contentType === "project") {
    return <RelatedProjects uid={`post-feed-${index}`} {...props} />;
  }
  if (props.contentType === "post") {
    return <RelatedPosts uid={`post-feed-${index}`} {...props} />;
  }
  if (props.contentType === "instagram") {
    const Instagram = loadable(() => import(`../../Instagram`));
    return <Instagram {...props} />;
  }
  return null;
};

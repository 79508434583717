import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RelatedPass } from "./RelatedPass";

const RelatedProjects = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          },
          allWordpressWpProjects {
            edges {
              node {
                ...ProjectListFields
              }
            }
          }
        }
      `}
      render={data => <RelatedPass {...props} data={data} title={props.title ? props.title : `Related<br/>Projects`} />}
    />
  );
};

export default RelatedProjects;

import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/lazy";
import PlayIcon from "./asset/play-icon-pilbara.svg";
import "./styles.scss";

/**
 * Renders a component with a responsive video. Uses /lazy package to reduce bundle size on output.
 * @param {string} video_url - URL for your video.
 * @param {string} className - custom wrapper className
 * @param {Object} placeholder_image - Placeholder image appears above video.
 */
export const VideoBlock = ({ video_url, placeholder_image, className }) => {
  return (
    <section
      className={`video-block ${
        className ? className : "video-block__project"
      }`}
    >
      <div className="video-block__inner">
        <div className="video-block__player-wrapper">
          <ReactPlayer
            className="react-player"
            url={video_url}
            light={placeholder_image?.source_url || true}
            width="100%"
            height="100%"
            playIcon={<img src={PlayIcon} />}
          />
        </div>
      </div>
    </section>
  );
};

VideoBlock.propTypes = {
  video_url: PropTypes.string,
  placeholder_image: PropTypes.shape({
    source_url: PropTypes.string,
  }),
};

VideoBlock.defaultProps = {
  video_url: "https://youtu.be/aqz-KE-bpKQ",
  placeholder_image: "",
};

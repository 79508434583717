import React from "react";
import Accordion from "../../Accordion";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./StaffProfiles.scss";

export const StaffProfiles = (props) => {
  const { staffProfiles, title, content } = props;
  return (
    <section className="staff-profiles">
      <div className="wrap">
        <div className="inner">
          <div className="intro">
            {title && <h3 className="section-title">{title}</h3>}
            {content && (
              <RenderContent className="intro-text" content={content} />
            )}
          </div>
          <div className="profiles">
            {staffProfiles &&
              staffProfiles.map((profile, index) => (
                <div className="profile" key={index}>
                  <div className="image">
                    <div className="aspect">
                      <ImagePass src={profile.image} />
                    </div>
                  </div>
                  <div className="content">
                    <h4 className="name">{profile.name}</h4>
                    <p className="position">{profile.position}</p>
                    <RenderContent content={profile.profile} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

import React, { useState } from "react";
import { Card } from "../../Card";
import Carousel from "../../Carousel";
import { ImagePass } from "../../Image/ImagePass";
import "./TileList.scss";

// cards used in the carousel with flip effect
const FlipCards = ({ tile }) => {
  const [isFlipped, setFlipped] = useState(false);
  return (
    <div onClick={() => setFlipped((previousState) => !previousState)}>
      <div
        className={`card card-default card-theme-dark span-1 ${
          isFlipped && "is-flipped"
        }`}
      >
        <div className="card-face card-front">
          <div className="card-image more-image-spacing">
            <div className="image-container">
              <ImagePass src={tile.image} />
            </div>
          </div>

          <div className="card-content align-center has-cta">
            <h3 className="card-heading">{tile.title}</h3>
          </div>
        </div>
        <div className="card-face card-back">
          <p>{tile.content}</p>
        </div>
      </div>
    </div>
  );
};

export const TileList = ({
  tiles,
  style,
  theme = "dark",
  sectionTitle,
  alignment = "left",
}) => {
  const carouselSettings = {
    container: "tiles",
    nav: false,
    mouseDrag: true,
    controls: false,
    loop: false,
    gutter: 28,
    slideBy: 1,

    controlsContainer: `.related-nav-controls`,
    responsive: {
      1600: {
        items: 3,
        controls: true,
      },
      1024: {
        items: 3,
      },
      640: {
        items: 2,
        controls: true,
      },
      420: {
        items: 1,
      },
    },
  };

  switch (style) {
    case "offset":
    case "grid":
      return (
        <section
          className={`tile-list tile-list-${style} tile-list-${alignment}`}
        >
          <div className="wrap">
            <div className="inner">
              {sectionTitle && (
                <span
                  className="h2 title"
                  dangerouslySetInnerHTML={{ __html: sectionTitle }}
                />
              )}
              <div className="cards">
                {tiles?.map((tile, index) => {
                  return (
                    <Card
                      key={`tile-${index}`}
                      heading={tile.title}
                      text={tile.content}
                      image={tile.image}
                      cta={tile.link}
                      ctaAlign="center"
                      theme={theme}
                      moreImageSpacing
                      alignContent="center"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      );

    case "small-icons":
      return (
        <section
          className={`tile-list tile-list-${style} tile-list-${alignment}`}
        >
          <div className="wrap">
            <div className="inner">
              {sectionTitle && (
                <span
                  className="h2 title"
                  dangerouslySetInnerHTML={{ __html: sectionTitle }}
                />
              )}
              <div className="cards">
                {tiles.map((tile, index) => {
                  return (
                    <Card
                      key={index}
                      heading={tile.title}
                      text={tile.content}
                      image={tile.image}
                      cta={tile.link}
                      ctaAlign="center"
                      theme={theme}
                      moreImageSpacing
                      alignContent="left"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      );

    case "slider":
      return (
        <section className="tile-list tile-list-slider">
          <div className="outer">
            <div className="wrap">
              <div className="inner">
                {sectionTitle && (
                  <span
                    className="h2 title"
                    dangerouslySetInnerHTML={{ __html: sectionTitle }}
                  />
                )}
                <div className="slider">
                  <div className={`slider-nav related-nav-controls`}>
                    <button type="button" title="Prev" className="prev" />
                    <button type="button" title="Next" className="next" />
                  </div>
                  <div className="tile=list-carousel">
                    <Carousel settings={carouselSettings}>
                      {tiles.map((tile, index) => {
                        return <FlipCards tile={tile} key={index} />;
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    default:
      return null;
  }
};
